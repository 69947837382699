<template>
  <div class="page-content h_iframe">
    <iframe frameborder="0" allowfullscreen :src="iframe_url"></iframe>
  </div>
</template>
<script>
import analytics from '../../api/analytics';

export default {
  name: 'BotAnalytics',
  metaInfo: {
    title: 'Bot Analytics',
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  data() {
    return {
      iframe_url: '',
    };
  },
  mounted() {
    this.generateURL();
  },
  methods: {
    async generateURL() {
      const response = await analytics.chatbotAnalytics({ workspace_id: this.activeWorkspace._id });
      if (response.error) {
        return;
      }
      this.iframe_url = response.data.iframe_url;
    },
  },
};
</script>
<style>
.h_iframe iframe {
  width: 100%;
  height: 100%;
}
.h_iframe {
  height: 100%;
  width: 100%;
}
</style>
